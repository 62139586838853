import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import {
  Account,
  AccountOutput,
  AccountOutputList,
  PointsTransactionList,
  AccountTypesList,
  AccountTypeOutput,
  AccountType,
  ReverseData,
  AddPointsData,
  NewDebitData,
} from '../models/points/models';

@Injectable({
  providedIn: 'root',
})
export class PointsService {
  baseUrl: string = environment.points_api.base_url;
  constructor(private httpService: HttpClient) {}

  listAccounts(filterParams: any): Observable<AccountOutputList> {
    const params = new HttpParams({ fromObject: filterParams });
    return this.httpService.get<AccountOutputList>(`${this.baseUrl}/accounts`, { params });
  }

  createAccount(props: Account): Observable<any> {
    return this.httpService.post(`${this.baseUrl}/accounts`, {
      ...props,
    });
  }

  getAccountById(id: string): Observable<AccountOutput> {
    return this.httpService.get<AccountOutput>(
      `${this.baseUrl}/accounts/${id}`
    );
  }

  listTransactionsByAccountId(id: string): Observable<PointsTransactionList> {
    const params = new HttpParams({ fromObject: { desc: 'createdAt' } });
    return this.httpService.get<PointsTransactionList>(
      `${this.baseUrl}/accounts/${id}/transactions`, { params }
    );
  }

  listAccountTypes() {
    return this.httpService.get<AccountTypesList>(`${this.baseUrl}/account-types`);
  }

  getAccountTypeById(id: string) {
    return this.httpService.get<AccountTypeOutput>(`${this.baseUrl}/account-types/${id}`);
  }

  createAccountType(props: AccountType) {
    return this.httpService.post(`${this.baseUrl}/account-types`, {
      ...props,
    });
  }

  editAccountType(props: AccountType) {
    return this.httpService.put(`${this.baseUrl}/account-types/${props.id}`, {
      ...props,
    });
  }

  expireTransaction(accountId: string, transactionId: string, force = false) {
    return this.httpService.post(
      `${this.baseUrl}/accounts/${accountId}/transactions/${transactionId}/expire`,
      force
    );
  }

  reverseTransaction(accountId: string, transactionId: string, data: ReverseData) {
    return this.httpService.post(
      `${this.baseUrl}/accounts/${accountId}/transactions/${transactionId}/reverse`,
      data
    );
  }

  addPoints(accountId: string, data: AddPointsData) {
    const url = `${this.baseUrl}/accounts/${accountId}/add-points`;
    return this.httpService.post(url, data);
  }

  addAdjustment(accountId: string, data: NewDebitData) {
    const url = `${this.baseUrl}/accounts/${accountId}/adjust`;
    return this.httpService.post(url, data);
  }

  listAccountsByUIDs(ids: string[]): Observable<AccountOutputList> {
    const header = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    };
    return this.httpService.post<AccountOutputList>(`${this.baseUrl}/list-accounts-by-uid`, 
      { 
        userList: [...ids] },
      { headers: header}
    );
  }
}
